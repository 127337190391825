body {
  background-image: url("/public/globe.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: fixed;
  background-position: center center;
  background-color: white;
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  
  body {
    background-color: black;
  }

  aside.help,
  .MuiDialog-container,
  .base-Popper-root,
  .footer p,
  .main-section {
    filter: invert(1);
  }
  .main-section .labelled-list li::before {
    filter: invert(1);
  }
}

.App {
  text-align: center;
}

.App header h1 {
  margin: 0 auto;
  padding: 0;
  font-size: 1.5em;
  user-select: none;
  width: 100%;
  border-bottom: solid 3px orange;
}

.App header h1 a {
  display: inline-block;
  position: relative;
  color: white;
  text-decoration: none;
}



.App header h1 span {
  display: inline-block;
  position: relative;
}

.App header h1 a {
  transition: all 0.333s ease-in-out;
}

.App header h1 a:hover {
  transform: skew(-10deg);
}

.App header h1 .green {
  color: #66ff77;
}

.App header h1 .mid {
  transform: scaleX(0.9) skew(-10deg);
}

.App header h1 .orange {
  color: orange;
}

.App header h3 {
  margin-top: 0;
  user-select: none;
  white-space: nowrap;
  font-size: 1.125em;
  margin-block-start: 0;
  margin-block-end: 0;
}

.App aside .MuiSvgIcon-root.close,
.App header .MuiSvgIcon-root {
  cursor: pointer;
  position: absolute;
  transition: all 0.25s ease-in-out;
}

.App header .MuiSvgIcon-root {
  right: 2.5%;
  bottom: 0.25em;
}

.App aside .MuiSvgIcon-root.close {
  right: 0.375em;
  top: 0.25em;
}

.App aside .MuiSvgIcon-root.close:hover,
.App header .MuiSvgIcon-root:hover {
  transform: scale(1.25);
}

.App header h3 time,
.App header h3 em,
.App header h3 span {
  display: inline-block;
  position: relative;
}

.App header h3 time {
  width: 3em;
  text-align: right;
}

.App header h3 .date {
  margin-right: 0.5em;
  color: cyan;
  transform: scale(0.9);
}

.App header h3 .seconds {
  text-align: left;
  width: 1.5em;
}

.App header h3 .seconds::before {
  content: ":";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: rgba(0, 32, 32, 0.75);
  min-height:4em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(0.5em + 2vmin);
  color: white;
  border-bottom: sold 3px rgba(255, 102, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 30;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.labelled-list {
  list-style: none;
}

.labelled-list li {
  padding: 0.25em 0; 
}

.labelled-list li span,
.labelled-list li strong,
.labelled-list li em {
  display: inline-block; 
}

.labelled-list li > strong:first-child {
  width: 6rem;
  margin-right: 0.25em;
}

.main-section,
.twin-grid {
  width: 21rem;
}

@media (min-width: 480px) {
  .main-section,
  .twin-grid {
    width: 24rem;
  }
}

.twin-grid {
  display: grid;
  grid-template-columns: 8rem 1fr;
  margin-block-start: 0;
  margin-block-end: 0;
}

.twin-grid dt {
  text-align: right;
  font-size: 0.8em;
  max-width: 10rem;
  margin-right: 0.375em;
}

.twin-grid dd {
  text-align: left;
  margin-inline-start: 0;
}

.twin-grid span,
.time-info-display em {
  display: inline-block;
}

.twin-grid span {
  margin-right: 0.5em;
}

.time-info-display .decimal-row span {
  margin-right: 0;
}

.time-info-display .decimal-row .dot {
  transform: scale(2) translateY(-0.25em);
  padding: 0 0.125em;
}

.twin-grid em:last-child {
  margin-left: 1em;
}

.twin-grid span:last-child {
  margin-right: 0;
}

.main-section {
  margin: 0 auto;
  padding: 5rem 0.5em 1em 0.5em;
  background-color: rgba(255,255,255, 0.66667);
  min-height: calc(100vh - 8rem);
  z-index: 2;
}

@media (min-width: 480px) and (min-height: 480px){
  .main-section {
    padding-top: 7.5rem;
  }
  @media (min-height: 600px){
    .main-section {
      padding-top: 9rem;
    }
  }
  
  @media (min-height: 700px){
    .main-section {
      padding-top: 10rem;
    }
  }
}



.main-section h4,
.main-section h3 {
  margin: 0 auto;
  margin-block-start: 0;
  margin-block-end: 0;
  width: 100%;
}

.main-section .inner h4,
.main-section .inner h3 {
  white-space: nowrap;
  user-select: none;
  color: #000066;
}

.main-section h4 {
  padding: 0.5em 0 0.125em 0;
}

.main-section h3 {
  padding: 0.75em 0 0.25em 0;
}

.location-display {
  min-height: 3em;
}

.location-display .coords {
  margin: 0 auto;
  display: block;
  margin-top: -0.125em;
  margin-bottom: 0.375em;
  user-select: none;
}

.location-display .coords span,
.location-display .coords strong {
  position: relative;
  display: inline-block;
}

.location-display .coords span.latitude {
  margin-right: 1em;
}

.location-display .coords > span > span {
  margin: 0 .125em;
}

.location-display .coords .polarity {
  transform: scale(0.9) translateY(-10%);
}

.location-display .coords .polarity::after {
  display: inline-block;
  position: relative;
  font-size: 0.75em;
}

.location-display .coords .latitude .polarity.positive {
  color: green;
}

.location-display .coords .latitude .polarity.positive::after {
  content: "↑";
}

.location-display .coords .latitude .polarity.negative {
  color: purple;
}

.location-display .coords .latitude .polarity.negative::after {
  content: "↑";
}

.location-display .coords .longitude .polarity.positive {
  color: blue;
}

.location-display .coords .longitude .polarity.positive::after {
  content: "→";
}

.location-display .coords .longitude .polarity.negative {
  color: coral;
}

.location-display .coords .longitude .polarity.negative::after {
  content: "←";
}


.seconds, .minutes {
  opacity: 0.8;
}

.large {
  font-size: 1.125em;
}

.small {
  font-size: 0.88889em;
}

.main-section > section {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.main-section .row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.main-section button sup {
  text-transform: lowercase;
}

.main-section .row button sup,
.main-section .row button strong {
  display: block;
}

.main-section .vertical button > strong:first-child {
  transform: scale(1.25);
}

.main-section .row button {
  min-width: 2em;
  padding: 0 0.25em;
  align-items: center;
  justify-content: center;
}

.main-section .row > div.MuiFormControl-root {
  margin: 0 0.5em;
  width: 10em;
}

.main-section .vertical button {
  flex-flow: column nowrap;
  min-width: 2em;
  max-height: 4.125em;
  padding: 0 0.25em;
  margin: 0 0.125em;
}

@media (max-width: 480px) {
  .main-section .vertical button {
    min-width: 1.5em;
  }

  .main-section .vertical button sup {
    font-size: 0.625em;
  }
}

.main-section .row.update-controls button {
  min-width: 3em;
  padding: 0 0.5em;
  height: 4.125em;
}

.moon-phase-display li > strong {
  color: gray;
}

.main-section button strong {
  font-size: 1.5em;
}

.inner .labelled-list {
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  width: 100%;
}

.inner .labelled-list li {
  white-space: nowrap;
}

.labelled-list > li strong {
  font-size: 0.75em;
  text-align: right;
  margin-right: 0.5em;
}

.labelled-list > li em,
.labelled-list > li strong {
  display: inline-block;
}

.labelled-list > li > em {
  width: 12em;;
  text-align: right;
  white-space: nowrap;
}

.labelled-list li::before {
  position: relative;
  display: inline-block;
  content: " ";
  width: 2em;
  font-size: 1.25em;
  margin-right: 0.5em;
}

.moon-phase-display li.q-1::before {
  content: "🌑";
}

.moon-phase-display li.q-2::before {
  content: "🌓";
}

.moon-phase-display li.q-3::before {
  content: "🌕";
}

.moon-phase-display li.q-4::before {
  content: "🌗";
}

.bodies-display li.body-me::before {
  content: "☿";
  color: gold;
}

.bodies-display li.body-ve::before {
  content: "♀";
  color: blue;
}

.bodies-display li.body-ma::before {
  content: "⚦";
  color: red;
}

.bodies-display li.body-ju::before {
  content: "♃";
  color: orange;
}

.bodies-display li.body-sa::before {
  content: "♄";
  color: lightcoral;
}

.bodies-display li.body-ur::before {
  content: "♅";
  color: cyan;
}

.bodies-display li.body-ne::before {
  content: "♆";
  color: turquoise;
}

.bodies-display li.body-pl::before {
  content: "♇";
  color: gray;
}

.main-section p {
  text-align: left;
}

.main-section p em,
.main-section p time {
  display: inline-block;
}

.main-section p.updated {
  margin-top: 1em;
  padding-top: 1em;
  border-top: dotted 2px black;
}

.main-section p.updated em {
  margin-right: 0.5em;
}

.main-section p.updated em::after {
  content: ":";
}

.main-section .numbers > .row {
  margin: auto;
  margin-bottom: 0.5em;
}

.footer {
  width: 90%;
  padding: 1em 5%;
  min-height: 3em;
  margin: 0 auto;
  border-top: solid 3px orange;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}


.footer p {
  margin-block-start: 0;
  margin-block-end: 0;
  text-align: center;
}

.footer p.copyright a,
.footer p.copyright span,
.footer p.copyright strong {
  display: inline-block;
}

.footer p.copyright strong {
  margin-right: 0.5em;
  transform: scale(1.5);
  color: orange;
}

.footer p.copyright span {
  margin-right: 0.25em;
}

.base-Popper-root li {
  text-align: left;
  padding: 0;
  margin: 0;
}

.base-Popper-root li svg {
  transform: scale(0.75) translate(-25%, 15%);
}

aside.help {
  position: fixed;
  top: 100vh;
  background-color: white;
  padding: 1em;
  transition: top 1s ease-in-out;
  pointer-events: none;
  left: 2.5%;
  right: 2.5%;
  opacity: 0;
  border: solid 2px gray;
  border-radius: 1em;
  text-align: left;
}

.show-help aside.help {
  top: 7.5rem;
  pointer-events: all;
  opacity: 1;
  height: calc(100vh - 16rem);
}

aside.help .inner {
  overflow: auto;
  height: 100%;
  max-height: calc(100vh - 16rem);
}

aside.help .inner .content {
  height: auto;
  max-height: none;
}

aside.help .content ol li {
  margin-bottom: 0.5em;
}

aside.help .content ol li h4 {
  margin: 0;
  padding: 0.25em 0;
}

aside.help .content ol li p {
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

@media (min-width: 800px) {
  .main-section {
    width: 48rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: calc(100vh - 16rem);
  }

  .main-section > .inner {
    width: 24rem;
    display: block;
  }
}

@media (min-width: 1250px) {
  .main-section {
    width: 72rem;
    grid-template-columns: 1fr 2fr;
    min-height: calc(100vh - 18rem);
  }

  aside.help {
    left: calc(50vw - 20rem);
    right: calc(50vw - 20rem);
  }


  @media (min-height: 600px) {
    .main-section {
      min-height: calc(100vh - 19rem);
    }
  }

  .main-section > .inner {
    width: 48rem;
    columns: 2;
    margin-bottom: 1.5em;
    column-gap: 2rem;
    padding-left: 1rem;
  }

  .main-section h3 {
    padding: 0 0 0.25em 0;
  }

  .main-section > .inner > .subsection {
    break-inside: avoid-column;
    width: 22rem;
  }
}

@media (min-width: 1500px) {
  .main-section {
    width: 96rem;
    display: grid;
    grid-template-columns: 1fr 3fr;
  }

  .main-section > .inner {
    width: 72rem;
    display: block;
    columns: 3;
  }
  .main-section > .inner > .subsection {
    break-inside: avoid-column;
  }
}